import React from 'react';
import BarHeatChart from '../Echarts/BarHeatChart';
import { ICategories } from '../ReportTrend';

const CategoryBarHeatChart = (props: { data: ICategories[] }) => {
  const { data } = props;
  const option = getOptions(data);
  return (
    <section>
      <div className='chart-container' style={{ height: '420px' }}>
        <BarHeatChart chartOption={option} />
      </div>
    </section>
  );
};

export default CategoryBarHeatChart;

const getOptions = (data: ICategories[] = []) => {
  // const allCount = data
  //   .map((category: ICategories) => parseInt(category.count, 10))
  //   .reduce((categoryCount, count) => categoryCount + count, 0);

  return {
    source: [
      ['score', 'amount', 'category'],
      ...data.map((category: ICategories) => {
        // const count = parseInt(category.count, 10);
        // const score = (count / allCount) * 100;
        return [category.count, category.count, category.name];
      }),
    ],
  };
};
