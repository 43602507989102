import _ from 'lodash';
import React from 'react';
import EchartComponent from './Echart';

const BarHeatChart = (props: { chartOption: IBarHeatOption }) => {
  const option = getOption(props.chartOption);
  return <EchartComponent options={option} />;
};

export default BarHeatChart;

interface IBarHeatOption {
  source: any;
}

const getOption = (params: IBarHeatOption) => {
  const values = params.source.map((s: any, index: number) => {
    if (index > 0) {
      return parseInt(s[1], 10);
    }
    return 0;
  });
  const maxValue = _.max(values);
  const minValue = _.min(values);

  return {
    dataset: {
      source: params.source,
    },
    grid: {
      left: 10,
      top: 10,
      bottom: 48,
      right: 64,
      containLabel: true,
    },
    xAxis: { name: 'amount' },
    yAxis: { type: 'category' },
    visualMap: {
      orient: 'horizontal',
      left: 'center',
      min: maxValue,
      max: minValue,
      text: ['High Score', 'Low Score'],
      // Map the score column to color
      dimension: 0,
      inRange: {
        color: ['#65B581', '#FFCE34', '#FD665F'],
      },
    },
    series: [
      {
        type: 'bar',
        encode: {
          // Map the "amount" column to X axis.
          x: 'amount',
          // Map the "category" column to Y axis
          y: 'category',
        },
      },
    ],
  };
};
